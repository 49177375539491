import React, { useEffect, useState } from "react";

//MUI
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

//Styles
import "../styles/SiteStatus.css";

//Actions
import { getAffiliateGroupList, getExternalSoftwareList, getSiteEC2InfoList, getSiteInfoList } from "../../Actions/index";

// Icons
import * as ImIcons from 'react-icons/im';

// Components
import SiteStatusList from './SiteStatusItem';
import EC2StatusItem from "./EC2StatusItem";
import { Checkbox, FormControlLabel, MenuItem, Select, TextField } from "@material-ui/core";

export default function SiteStatus (props) {
  const [refresh, setRefresh] = useState(true);
  const [ec2InfoList, setEc2InfoList] = useState([]);
  const [siteInfoList, setSiteInfoList] = useState([]);
  const [externalSoftwareList, setExternalSoftwareList] = useState([]);
  const [selectedExtSoftware, setSelectedExtSoftware] = useState("-1");
  const [affiliateGroupList, setAffiliateGroupList] = useState([]);
  const [selectedAffiliateGroup, setSelectedAffiliateGroup] = useState("-1");
  const [selectedSubStatus, setSelectedSubStatus] = useState("-1");
  const [filter, setFilter] = useState("");
  const [stateList, setStateList] = useState([]);
  const [selectedState, setSelectedState] = useState("-1");
  const [isSpecialty, setIsSpecialty] = useState(false);
  const [isEc2Stopped, setIsEc2Stopped] = useState(false);
  const [isAppStopped, setIsAppStopped] = useState(false);

  useEffect(() => {
    function getSiteData() {
      getSiteInfoList()
        .then((result) => {
          const sortedSiteInfoList = result.data.sort((a, b) => a.site_name.localeCompare(b.site_name));
          setSiteInfoList(sortedSiteInfoList);
          const stateList = [...new Set(sortedSiteInfoList.map(site => site.company_state))].filter(s => !!s);
          setStateList(stateList);
        })
        .catch((err) => {
          console.log(err);
        });

      getSiteEC2InfoList()
        .then((result) => {
          const sortedEc2InfoList = result.data.sort((a, b) => a.name.localeCompare(b.name));
          setEc2InfoList(sortedEc2InfoList);
        })
        .catch((err) => {
          console.log(err);
        });
      
      getExternalSoftwareList()
        .then((result) => {
          setExternalSoftwareList(result.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
      
      getAffiliateGroupList()
        .then((result) => {
          setAffiliateGroupList(result.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (refresh) {
      getSiteData();
      setRefresh(false);
    }
  }, [refresh]);

  const fuzzySearch = (name, company, searchTerm) => {
    if (!searchTerm) return true;

    const regex = new RegExp(`${searchTerm}`, "gi");
    
    if (name && regex.test(name)) return true;
    if (company && regex.test(company)) return true;
    
    return false;
  }

  const checkSiteFilters = (site) => {

    if (selectedExtSoftware == "-1" && selectedAffiliateGroup == "-1" && selectedSubStatus == "-1" && selectedState == "-1") {
      // return true if there are no filters turned on
      return true;
    }
    
    if (!site) return false;

    let isVisible = true;

    if (selectedExtSoftware != "-1" && (site.external_software_id?.toString() ?? "") != selectedExtSoftware) {
      isVisible = false;
    }

    if (selectedAffiliateGroup != "-1" && (site.affiliate_group_id?.toString() ?? "") != selectedAffiliateGroup) {
      isVisible = false;
    }

    if (selectedSubStatus != "-1" && (site.subscription_status?.toString() ?? "") != selectedSubStatus) {
      isVisible = false;
    }

    if (selectedState != "-1" && (site.company_state?.toString() ?? "") != selectedState) {
      isVisible = false;
    }
    
    return isVisible;
  }

  const checkEc2Filters = (ec2Instance) => {
    if (!ec2InfoList.length) return true;

    if (!isSpecialty && !isEc2Stopped && !isAppStopped) {
      // return true if there are no filters turned on
      return true;
    }
    
    if (!ec2Instance) return false;

    let isVisible = true;

    if (isSpecialty && ec2Instance.tags && ec2Instance.tags.find(tag => tag.Key === "PRODUCTION_CLIENT")) {
      isVisible = false;
    }

    if (isEc2Stopped && ec2Instance.status === "running") {
      isVisible = false;
    }

    if (isAppStopped && ec2Instance.apiStatus.applicationStatus === "Available") {
      isVisible = false;
    }
    
    return isVisible;
  }

  let recordCount = 0;

  const siteStatusList = siteInfoList.filter((site) => fuzzySearch(site.site_name, site.company_name, filter) && checkSiteFilters(site)).map((site) => {
    const ec2Data = site.active_ind == 1 ? ec2InfoList.find(ec2Instance => ec2Instance.name === site.site_name) : undefined;
    const externalSoftwareName = externalSoftwareList?.find(s => s.external_software_id === site.external_software_id)?.name;
    const affiliateGroupName = affiliateGroupList?.find(g => g.affiliate_group_id === site.affiliate_group_id)?.name;
    const isVisible = checkEc2Filters(ec2Data);

    if (isVisible) recordCount++;

    return (
      <SiteStatusList
        key={site.site_id + '-' + site.site_name}
        site={site}
        ec2Data={ec2Data}
        hide={props.hide} 
        isVisible={isVisible}
        externalSoftwareName={externalSoftwareName}
        affiliateGroupName={affiliateGroupName}
        isEc2Loaded={ec2InfoList.length > 0}
        refresh={() => setRefresh(true)}
      />
    )
  });

  const ec2filteredList = ec2InfoList.filter(ec2Instance => !siteInfoList.find(site => site.site_name === ec2Instance.name));
  const ec2StatusList = ec2filteredList.filter((ec2Instance) => fuzzySearch(ec2Instance.name, undefined, filter) && checkSiteFilters(undefined)).map(ec2Instance => {
    const isVisible = checkEc2Filters(ec2Instance);

    if (isVisible) recordCount++;

    return (
      <EC2StatusItem 
        key={ec2Instance.name}
        ec2Data={ec2Instance}
        isVisible={isVisible}
        refresh={() => setRefresh(true)}
      />
    )
  });

  return (
    <div className="container">
      <Paper elevation={3}>
        <div className="siteinfo__header">
          <Typography variant="h4">
            Site Status
          </Typography>
        </div>
        <div style={{borderBottom: "1px solid #838383", textAlign: "left", padding: "10px 10px 20px"}}>
          <div style={{display: "inline-block", verticalAlign: "top", margin: "15px 40px 15px 0px"}}>
            <Typography style={{margin: "auto auto 5px"}}>Name/Site Search</Typography>
            <TextField 
              label="Search by name or site" 
              variant="filled" 
              style={{width: "250px"}} 
              value={filter} 
              onChange={(e) => setFilter(`${e.target.value}`)}
            />
          </div>
          <div style={{display: "inline-block", verticalAlign: "top", margin: "15px 40px 15px 0px"}}>
            <Typography style={{margin: "auto auto 5px"}}>State</Typography>
            <Select
              value={selectedState}
              onChange={(e) => setSelectedState(e.target.value)}
              style={{width: "200px"}} 
              variant="filled"
              displayEmpty
              fullWidth
            >
              <MenuItem value={"-1"}>
                All States
              </MenuItem>
              {stateList.sort((a, b) => a.localeCompare(b)).map((state) => {
                return (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div style={{display: "inline-block", verticalAlign: "top", margin: "15px 40px 15px 0px"}}>
            <Typography style={{margin: "auto auto 5px"}}>External Software</Typography>
            <Select
              value={selectedExtSoftware}
              onChange={(e) => setSelectedExtSoftware(e.target.value)}
              style={{width: "200px"}} 
              variant="filled"
              displayEmpty
              fullWidth
            >
              <MenuItem value={"-1"}>
                All Options
              </MenuItem>
              <MenuItem value={""}>
                No Software
              </MenuItem>
              {externalSoftwareList.map((software) => {
                return (
                  <MenuItem key={software.external_software_id} value={software.external_software_id}>
                    {software.name}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div style={{display: "inline-block", verticalAlign: "top", margin: "15px 40px 15px 0px"}}>
            <Typography style={{margin: "auto auto 5px"}}>Affiliate Group</Typography>
            <Select
              value={selectedAffiliateGroup}
              onChange={(e) => setSelectedAffiliateGroup(e.target.value)}
              style={{width: "200px"}} 
              variant="filled"
              displayEmpty
              fullWidth
            >
              <MenuItem value={"-1"}>
                All Options
              </MenuItem>
              <MenuItem value={""}>
                No Group
              </MenuItem>
              {affiliateGroupList.map((group) => {
                return (
                  <MenuItem key={group.affiliate_group_id} value={group.affiliate_group_id}>
                    {group.name}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div style={{display: "inline-block", verticalAlign: "top", margin: "15px 40px 15px 0px"}}>
              <Typography style={{margin: "auto auto 5px"}}>Subscription Status</Typography>
              <Select
                value={selectedSubStatus}
                onChange={(e) => setSelectedSubStatus(e.target.value)}
                variant="filled"
                style={{width: "200px"}} 
                displayEmpty
                fullWidth
              >
                <MenuItem value={"-1"}>
                  All Statuses
                </MenuItem>
                <MenuItem value={""}>
                  No Status
                </MenuItem>
                <MenuItem value={"0"}>
                  Active
                </MenuItem>
                <MenuItem value={"1"}>
                  Deactivated
                </MenuItem>
              </Select>
          </div>
          <div style={{display: "inline-block", verticalAlign: "top", margin: "15px 40px 15px 0px"}}>
            <Typography>EC2 Filters</Typography>
            <FormControlLabel
              control={<Checkbox checked={isSpecialty} disabled={!ec2InfoList.length > 0} onChange={() => setIsSpecialty(!isSpecialty)} name="checkedSS" />}
              label="Specialty Servers"
            />
            <FormControlLabel
              control={<Checkbox checked={isEc2Stopped} disabled={!ec2InfoList.length > 0} onChange={() => setIsEc2Stopped(!isEc2Stopped)} name="checkedEc2" />}
              label="EC2 Not Running"
            />
            <FormControlLabel
              control={<Checkbox checked={isAppStopped} disabled={!ec2InfoList.length > 0} onChange={() => setIsAppStopped(!isAppStopped)} name="checkedApp" />}
              label="App Not Running"
            />
          </div>
          <Typography style={{marginTop: "20px"}}>Record Count: {recordCount}</Typography>
        </div>
        { siteInfoList.length ? (
            <div>
              {siteStatusList}
              {ec2StatusList}
            </div>
          ) : (
            <div>
              <div className="loading-container">
                <Typography variant="h4" className="loading-text">
                  Site data is loading... {<ImIcons.ImSpinner9 size={"60"} className="rotate" />}
                </Typography>
              </div>
            </div>
          )
        }
      </Paper>
    </div>
  );
}